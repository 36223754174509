import React, { useRef } from "react";
import { useState } from "react";
import Privacy from "./Privacy";
import SeePost from "./SeePost";
import Location from "./Location";
import ShareGroup from "./ShareGroup";
import ExceptContact from "./ExceptContact";
import OnlyShareWith from "./OnlyShareWith";
import PostVisibility from "./PostVisibility";
import axiosRequest from "../../Middleware/api";
import SliderPopup from "./SliderPopup";
import { useSelector, useDispatch } from "react-redux";
import { useSwiperSlider } from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setTogglePostAction,
  setToggeleViewOtherProfile,
  setScrollToPost,
} from "../../Redux/actions";
import {
  ManageTeparsPrivacy,
  ManageTeparsPrivacyValue,
} from "../../Utils/helpers";
import { ERROR, CONTACT_EXCEPT, ONLY_SHARE_WITH } from "../../Utils/enums";

import "../../assets/css/tepars.scss";

function EditPostPrivacy({ onClose }) {
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);

  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const togglePost = useSelector((state) => state.togglePost);
  const postDetails = useSelector((state) => state.postDetails);

  const media = postDetails?.media;
  const EditPrivacy = postDetails?.privacy?.split("-")[0];
  const EditPost = postDetails && togglePost === "EditPost";
  const GroupsId = postDetails?.group?.map((group) => group.GroupId._id);
  const ArrayContactIds = postDetails?.contact?.map((item) => item?.userId);
  const ContactIds = postDetails?.contact?.map((item) => item?.userId?._id);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [getSelectedLocation, setGetSelectedLocation] = useState("");
  const [handlePrivacyOptions, setHandlePrivacyOptions] = useState("Privacy");
  const [privacy, setPrivacy] = useState(ManageTeparsPrivacyValue(EditPrivacy));
  const [getDescription, setGetDescription] = useState(
    EditPost ? postDetails?.description : ""
  );
  const [getVisibility, setGetVisibility] = useState(
    EditPost ? postDetails?.expireAt.toString() : "60"
  );
  const [exceptContact, setExceptContact] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ContactIds ?? [] : []
  );
  const [onlyShareContact, setOnlyShareContact] = useState(
    EditPost && privacy === ONLY_SHARE_WITH ? ContactIds ?? [] : []
  );
  const [getContactArray, setGetContactArray] = useState(
    EditPost && privacy === CONTACT_EXCEPT ? ArrayContactIds ?? [] : []
  );
  const [getContactArrayOnly, setGetContactArrayOnly] = useState(
    EditPost && privacy === ONLY_SHARE_WITH ? ArrayContactIds ?? [] : []
  );
  const [getRePosting, setGetRePosting] = useState(
    EditPost ? postDetails?.settingRepost : true
  );
  const [getOffComment, setGetOffComment] = useState(
    EditPost ? postDetails?.turnOffComment : false
  );
  const [getCanReplyPost, setGetCanReplyPost] = useState(
    EditPost ? postDetails?.canReply : false
  );
  const [sharedGroups, setSharedGroups] = useState(GroupsId ?? []);
  const [sharedGroupsList, setSharedGroupsList] = useState(
    EditPost ? postDetails?.group ?? [] : []
  );

  var countGetContactExcept = exceptContact?.length ?? 0;
  var countGetOnlyShareWith = onlyShareContact?.length ?? 0;
  const imagesList = postDetails?.media;

  const HandleDoneClick = () => {
    if (handlePrivacyOptions === "ContactExcept") {
      setHandlePrivacyOptions("SeePost");
    } else if (handlePrivacyOptions === "OnlyShareWith") {
      setHandlePrivacyOptions("SeePost");
    } else {
      setHandlePrivacyOptions("Privacy");
    }
  };

  const HandleBackClick = () => {
    if (
      handlePrivacyOptions === "ContactExcept" ||
      handlePrivacyOptions === "OnlyShareWith"
    ) {
      setHandlePrivacyOptions("SeePost");
    } else if (
      handlePrivacyOptions === "SeePost" ||
      handlePrivacyOptions === "Location" ||
      handlePrivacyOptions === "ShareGroup" ||
      handlePrivacyOptions === "PostVisibility"
    ) {
      setHandlePrivacyOptions("Privacy");
    } else {
      dispatch(setPostDetails(null));
      dispatch(setTogglePostAction(null));
    }
  };

  const HandleEditPostPrivacy = () => {
    // Update only description of post
    if (descriptionEdit) {
      const editData = {
        type: "edit",
        postId: [postDetails?._id],
        description: getDescription,
      };
      if (editData) {
        axiosRequest
          .post(`post/functionality`, editData)
          .then((res) => {
            setDescriptionEdit(false);
            dispatch(setPostDetails(null));
            dispatch(setTogglePostAction(null));
            onClose();
          })
          .catch((error) => {
            if (error?.response?.status !== 401) {
              dispatch(handleToast(ERROR));
            }
            onClose();
          });
      }
    }

    // Update all post details except description of post
    const editPrivacyData = {
      type: "editPrivacy",
      postId: [postDetails?._id],
      canReply: getCanReplyPost,
      contact:
        privacy === CONTACT_EXCEPT
          ? exceptContact
          : privacy === ONLY_SHARE_WITH
          ? onlyShareContact
          : [],
      expireAt: getVisibility,
      group: sharedGroups,
      privacy: ManageTeparsPrivacy(privacy, sharedGroups?.length, postDetails),
      settingRepost: getRePosting,
      turnOffComment: getOffComment,
      description: getDescription,
      location: {
        longitude: getSelectedLocation?.geometry?.location?.lng,
        name: getSelectedLocation?.name,
        latitude: getSelectedLocation?.geometry?.location?.lat,
      },
    };

    axiosRequest
      .post(`post/functionality`, editPrivacyData)
      .then((res) => {
        dispatch(setPostDetails(null));
        dispatch(setTogglePostAction(null));
        const updatedTepars = Tepars?.map((post) =>
          post?._id === res?.data?.data[0]?._id ? res?.data?.data[0] : post
        );
        dispatch(setTepars(updatedTepars));
        onClose();
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
        onClose();
      });
  };

  const HandleRePost = () => {
    const rePostData = {
      location: {},
      description: getDescription,
      privacy: ManageTeparsPrivacy(privacy, sharedGroups?.length, ""),
      group: sharedGroups,
      contact:
        privacy === CONTACT_EXCEPT
          ? exceptContact
          : privacy === ONLY_SHARE_WITH
          ? onlyShareContact
          : [],
      tagUser: [],
      media: postDetails.media,
      RepostPostId: postDetails?._id,
      expireAt: getVisibility,
      canReply: getCanReplyPost,
      turnOffComment: getOffComment,
      settingRepost: getRePosting,
    };

    if (rePostData) {
      axiosRequest
        .post(`post/create`, rePostData)
        .then((res) => {
          if (res?.data?.status === "500") {
            dispatch(handleToast(res?.data?.message));
          } else {
            dispatch(setTepars([res?.data?.data, ...Tepars]));
            dispatch(setToggeleViewOtherProfile(false));
            dispatch(setScrollToPost(true));
            // dispatch(setPostCreateProgress(true));
            // dispatch(setPostDetails(res?.data?.data));
          }
          onClose();
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
          }
          onClose();
        });
    }
  };

  let width = "43.75rem";
  let height = "85vh";

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth <= 1024 && viewportHeight <= 1380) {
    width = "40rem";
    height = "30rem";
  } else if (viewportWidth <= 1440 || viewportWidth < 1600) {
    width = "60rem";
  } else if (viewportWidth > 1600) {
    width = "60rem";
    height = "40rem";
  }

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, media.length);

  return (
    <div className="uploadTepars">
      <div
        className="uploadTepars-popup"
        // style={{ width, height }}
      >
        <main className="container" style={{ display: "flex" }}>
          <div
            className="upload-image-container"
            ref={sliderWrapperRef}
            style={{
              // width: "450px",
              // height: "562.5px",
              // width: "450px",
              // height: "500px",
              aspectRatio: postDetails.media[0].aspectRatio,
            }}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              post={postDetails}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />

            {/* show post length */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}

            {/* <div className="gradient" aria-hidden="true" /> */}
          </div>

          <div className="filters">
            {/* header */}
            <div className="header">
              <div
                className="btn btn-back"
                onClick={() => HandleBackClick()}
                tabIndex="0"
                aria-label="Back"
                role="button"
              >
                <svg width="20" height="20" viewBox="0 0 26 26" fill="none">
                  <title>Back</title>
                  <path
                    d="M12.3766 1L1 12.9995L12.3766 25M25 12.9995H1.36"
                    stroke={
                      theme === "Light"
                        ? "#1891a2"
                        : theme === "Dark"
                        ? "#ffffff"
                        : window.matchMedia("(prefers-color-scheme: light)")
                            .matches
                        ? "#1891a2"
                        : "#ffffff"
                    }
                    strokeWidth="2"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="heading">
                <h1 className="title">
                  {togglePost === "EditPost" && "Edit post"}
                  {togglePost === "RePost" && "Repost"}
                </h1>
              </div>
              {handlePrivacyOptions !== "Privacy" &&
                handlePrivacyOptions !== "Location" && (
                  <div className="next" onClick={HandleDoneClick} tabIndex="0">
                    <span>Done</span>
                  </div>
                )}
              {togglePost === "EditPost" &&
                handlePrivacyOptions === "Privacy" && (
                  <div
                    className="next"
                    onClick={HandleEditPostPrivacy}
                    tabIndex="0"
                  >
                    <span>Done</span>
                  </div>
                )}
              {togglePost === "RePost" &&
                handlePrivacyOptions === "Privacy" && (
                  <div className="next" onClick={HandleRePost} tabIndex="0">
                    <span>Post</span>
                  </div>
                )}
            </div>
            {/* PRIVACY */}
            {handlePrivacyOptions === "Privacy" && (
              <div className="filters-wrapper">
                <Privacy
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                  getDescription={getDescription}
                  setGetDescription={setGetDescription}
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                  getVisibility={getVisibility}
                  getRePosting={getRePosting}
                  setGetRePosting={setGetRePosting}
                  getOffComment={getOffComment}
                  setGetOffComment={setGetOffComment}
                  getCanReplyPost={getCanReplyPost}
                  setGetCanReplyPost={setGetCanReplyPost}
                  getSelectedLocation={getSelectedLocation}
                  sharedGroups={sharedGroups}
                  sharedGroupsList={sharedGroupsList}
                  setDescriptionEdit={setDescriptionEdit}
                />
              </div>
            )}
            {/* LOCATION */}
            {handlePrivacyOptions === "Location" && (
              <div className="filters-wrapper">
                <Location
                  getSelectedLocation={getSelectedLocation}
                  setGetSelectedLocation={setGetSelectedLocation}
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                />
              </div>
            )}
            {/* SEEPOST */}
            {handlePrivacyOptions === "SeePost" && (
              <div className="filters-wrapper">
                <SeePost
                  privacy={privacy}
                  setPrivacy={setPrivacy}
                  countContactExcept={countGetContactExcept}
                  countGetOnlyShareWith={countGetOnlyShareWith}
                  setHandlePrivacyOptions={setHandlePrivacyOptions}
                />
              </div>
            )}
            {/* SHARECONTACT */}
            {handlePrivacyOptions === "ContactExcept" && (
              <div className="filters-wrapper">
                <ExceptContact
                  getContactExcept={exceptContact}
                  setGetContactExcept={setExceptContact}
                  getContactArray={getContactArray}
                  setGetContactArray={setGetContactArray}
                />
              </div>
            )}
            {/* ONLYSHAREWITH */}
            {handlePrivacyOptions === "OnlyShareWith" && (
              <div className="filters-wrapper">
                <OnlyShareWith
                  getOnlyShareWith={onlyShareContact}
                  setGetOnlyShareWith={setOnlyShareContact}
                  getContactArray={getContactArrayOnly}
                  setGetContactArray={setGetContactArrayOnly}
                />
              </div>
            )}
            {/* SHAREGROUP */}
            {handlePrivacyOptions === "ShareGroup" && (
              <div className="filters-wrapper">
                <ShareGroup
                  sharedGroups={sharedGroups}
                  setSharedGroups={setSharedGroups}
                  sharedGroupsList={sharedGroupsList}
                  setSharedGroupsList={setSharedGroupsList}
                />
              </div>
            )}
            {/* POSTVISIBILITY */}
            {handlePrivacyOptions === "PostVisibility" && (
              <div className="filters-wrapper">
                <PostVisibility
                  getVisibility={getVisibility}
                  setGetVisibility={setGetVisibility}
                />
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default EditPostPrivacy;

import { React, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSetting, setTheme } from "../../Redux/actions";
import "../../assets/css/themePopup.scss";

function ThemePopup() {
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [isClosing, setIsClosing] = useState(false);

  const themeRefs = {
    Light: useRef(null),
    Dark: useRef(null),
    Default: useRef(null),
  };

  const handleKeyDown = (e, currentTheme) => {
    const themes = ["Light", "Dark", "Default"];
    const currentIndex = themes.indexOf(currentTheme);

    if (e.key === "ArrowDown") {
      const nextIndex = (currentIndex + 1) % themes.length;
      themeRefs[themes[nextIndex]].current.focus();
    } else if (e.key === "ArrowUp") {
      const prevIndex = (currentIndex - 1 + themes.length) % themes.length;
      themeRefs[themes[prevIndex]].current.focus();
    } else if (e.key === "Enter") {
      setSelectedTheme(currentTheme);
    }
  };

  const applyTheme = () => {
    setIsClosing(true);
    setTimeout(() => {
      const body = document.body;
      body.classList.remove("dark", "light");

      if (selectedTheme === "Light") {
        body.classList.remove("dark");
        body.classList.add("light");
      } else if (selectedTheme === "Dark") {
        body.classList.remove("light");
        body.classList.add("dark");
      } else if (selectedTheme === "Default") {
        const prefersLightTheme = window.matchMedia(
          "(prefers-color-scheme: light)"
        ).matches;
        const prefersDarkTheme = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        if (prefersDarkTheme) {
          body.classList.remove("light");
          body.classList.add("dark");
        } else if (prefersLightTheme) {
          body.classList.remove("dark");
          body.classList.add("light");
        }
      }
      dispatch(setTheme(selectedTheme));
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      dispatch(setSelectedSetting(""));
    }, 250);
  };

  return (
    <div className={`themePopup ${isClosing ? "closing" : ""}`}>
      <div className={`dialog ${isClosing ? "closing" : ""}`}>
        <div className="dialog-info">
          <h4>Theme</h4>
        </div>
        <div className="dialog-info-wrapper">
          <div className="light-wrapper">
            <div className="group-check">
              <input
                id="lightMode"
                type="radio"
                aria-label="Select Light theme"
                value="Light"
                checked={selectedTheme === "Light"}
                onChange={() => setSelectedTheme("Light")}
                aria-checked={selectedTheme === "Light"}
              />
              <div
                className="checkmark"
                role="radio"
                tabIndex={0}
                ref={themeRefs.Light}
                aria-checked={selectedTheme === "Light"}
                aria-labelledby="lightModeLabel"
                onClick={() => setSelectedTheme("Light")}
                onKeyDown={(e) => handleKeyDown(e, "Light")}
              >
                <div
                  className={`offRadio radio-cmn ${
                    selectedTheme === "Light" ? "active" : ""
                  }`}
                ></div>
                <div
                  className={`onRadio radio-cmn ${
                    selectedTheme === "Light" ? "active" : ""
                  }`}
                ></div>
              </div>
            </div>
            <label
              id="lightModeLabel"
              className="description"
              htmlFor="lightMode"
            >
              Light
            </label>
          </div>

          <div className="dark-wrapper">
            <div className="group-check">
              <input
                id="darkMode"
                type="radio"
                aria-label="Select Dark theme"
                value="Dark"
                checked={selectedTheme === "Dark"}
                onChange={() => setSelectedTheme("Dark")}
                aria-checked={selectedTheme === "Dark"}
              />
              <div
                className="checkmark"
                role="radio"
                tabIndex={0}
                ref={themeRefs.Dark}
                aria-checked={selectedTheme === "Dark"}
                aria-labelledby="darkModeLabel"
                onClick={() => setSelectedTheme("Dark")}
                onKeyDown={(e) => handleKeyDown(e, "Dark")}
              >
                <div
                  className={`offRadio radio-cmn ${
                    selectedTheme === "Dark" ? "active" : ""
                  }`}
                ></div>
                <div
                  className={`onRadio radio-cmn ${
                    selectedTheme === "Dark" ? "active" : ""
                  }`}
                ></div>
              </div>
            </div>
            <label
              id="darkModeLabel"
              className="description"
              htmlFor="darkMode"
            >
              Dark
            </label>
          </div>

          <div className="systemDefault-wrapper">
            <div className="group-check">
              <input
                id="default"
                type="radio"
                aria-label="Select System Default theme"
                value="Default"
                checked={selectedTheme === "Default"}
                onChange={() => setSelectedTheme("Default")}
                aria-checked={selectedTheme === "Default"}
              />
              <div
                className="checkmark"
                role="radio"
                tabIndex={0}
                ref={themeRefs.Default}
                aria-checked={selectedTheme === "Default"}
                aria-labelledby="defaultModeLabel"
                onClick={() => setSelectedTheme("Default")}
                onKeyDown={(e) => handleKeyDown(e, "Default")}
              >
                <div
                  className={`offRadio radio-cmn ${
                    selectedTheme === "Default" ? "active" : ""
                  }`}
                ></div>
                <div
                  className={`onRadio radio-cmn ${
                    selectedTheme === "Default" ? "active" : ""
                  }`}
                ></div>
              </div>
            </div>
            <label
              id="defaultModeLabel"
              className="description"
              htmlFor="default"
            >
              System default
            </label>
          </div>
        </div>

        <div className="cta">
          <button className="btn cancel" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn for-me active" onClick={() => applyTheme()}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default ThemePopup;
